import languages from "./languages";

const lang = {
  message: {
    hello: "こんにちは、世界",
  },
  languages,
};

export default lang;
